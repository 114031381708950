<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars')"
               title="Автомобили">
          Автомобили
        </label>
        <label class="btn btn-primary font-scale-2 font-medium">
          {{ page_title }}
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars/calendar')"
               title="Календарь">
          Календарь
        </label>
      </h3>

      <div class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_cars.show_only = 'my';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <template v-if="[1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)">
          <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
                 @input="() => {
                 filter_cars.show_only = 'all';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'all'">
          <label class="btn btn-outline-primary" for="show_only-all">Все</label>
        </template>
      </div>
    </div>

    <transition name="fade">
      <div class="form-group">
        <div class="row">
          <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
            <multiselect :allow-empty="true"
                         :options="branches"
                         :searchable="false"
                         :show-labels="true"
                         id="filter-by-branch"
                         @input="() => {
                             filter_cars.branch_id = branch ? branch.id : null;
                             updateFilters();
                           }"
                         deselect-label="Убрать"
                         label="name"
                         placeholder="Филиал"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Филиал"
                         track-by="id"
                         v-model="branch">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
              <template slot="singleLabel" slot-scope="props">
                {{ props.option.title }}, {{ props.option.address }}
              </template>
              <template slot="option" slot-scope="props">
                {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
              </template>
            </multiselect>
          </label>

          <div v-if="filter_cars.show_only !== 'my'" class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <multiselect :allow-empty="true"
                         :options="employees"
                         :searchable="true"
                         id="filter-by-responsible"
                         @input="() => {
                             filter_cars.responsible_id = responsible ? responsible.id : null;
                             updateFilters();
                           }"
                         deselect-label="Убрать"
                         label="title"
                         placeholder="Ответственный"
                         required
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Ответственный"
                         track-by="id"
                         v-model="responsible">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
          </div>

          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <multiselect :allow-empty="true"
                         :options="marks"
                         :searchable="true"
                         id="filter-by-mark"
                         @input="() => {
                             filter_cars.mark_id = mark ? mark.id : null;
                             updateFilters();
                           }"
                         @select="getCarModels"
                         deselect-label="Убрать"
                         label="name"
                         placeholder="Марка"
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Марка"
                         track-by="id"
                         v-model="mark">
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
          </div>

          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <multiselect :allow-empty="true"
                         :disabled="!models || models.length === 0"
                         :options="models"
                         :searchable="true"
                         id="filter-by-model"
                         @input="() => {
                             filter_cars.model_id = model ? model.id : null;
                             updateFilters();
                           }"
                         deselect-label="Убрать"
                         label="name"
                         placeholder="Модель"
                         select-label="Выбрать"
                         selected-label="Выбрано"
                         title="Модель"
                         track-by="id"
                         v-model="model">
              <template slot="placeholder">
                <i class="fas font-scale fa-circle-notch fa-spin text-blue" v-if="onProgressCarModels === true" />
                Модель
              </template>
              <template slot="noResult">Не найдено</template>
              <template slot="noOptions">Данные отсутствуют</template>
            </multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <input class="pointer margin-l-5"
                   style="transform: scale(1.2);"
                   id="filter-by-only-disabled"
                   type="checkbox"
                   @input="() => {
                       filter_cars.only_sold = !filter_cars.only_sold;
                       filter_cars.sell_reject = false;
                       updateFilters();
                     }"
                   v-model="filter_cars.only_disabled">
            <label for="filter-by-only-disabled" class="margin-l-5 padding-l-10">
              Только проданные
            </label>
          </div>

          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <input class="pointer margin-l-5"
                   style="transform: scale(1.2);"
                   id="filter-by-only-company-car"
                   type="checkbox"
                   @input="() => {
                       filter_cars.only_sold = false;
                       filter_cars.sell_reject = !filter_cars.sell_reject;
                       updateFilters();
                     }"
                   v-model="filter_cars.without_owner_cars">
            <label for="filter-by-only-company-car" class="margin-l-5 padding-l-10">
              Только снятые с продажи
            </label>
          </div>

          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <label class="col-xl-2 col-md-6 col-sm-12 mb-1" for="filter-by-sorting-date-car">
              <select class="form-control" id="filter-by-sorting-date-car"
                      v-model="filter_cars.sorting_date_car"
                      @change="updateFilters">
                <option :value="null" class="text-muted">
                  Сортировка по дате (автомобили)
                </option>
                <option value="oldest">
                  Сначала старые
                </option>
                <option value="newest">
                  Сначала новые
                </option>
              </select>
            </label>
          </div>

          <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
            <label class="col-xl-2 col-md-6 col-sm-12 mb-1" for="filter-by-sorting-date-comment">
              <select class="form-control" id="filter-by-sorting-date-comment"
                      v-model="filter_cars.sorting_date_comment"
                      @change="updateFilters">
                <option :value="null" class="text-muted">
                  Сортировка по дате (комментарии)
                </option>
                <option value="oldest">
                  Сначала старые
                </option>
                <option value="newest">
                  Сначала новые
                </option>
              </select>
            </label>
          </div>
        </div>
      </div>
    </transition>

    <div class="box-body">
      <table-items :data_search="data_search"
                   :filters_query="filter_cars"
                   :relations="relations"
                   :relations_count="relations_count"
                   component="car-selling-item"
                   :columns="columns"
                   api_resource="/cars/selling">
      </table-items>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TableItems from './../../../components/pagination/table-items';
import BranchService from "@/services/BranchService";
import Multiselect from "vue-multiselect";

Vue.component('car-selling-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: "cars-selling",

  components: {
    TableItems,
    Multiselect,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Продавцы",

    columns: [
      {
        title: 'Авто',
        class: null,
        style: 'background: rgba(160, 160, 157, 0.5)',
      },
      {
        title: 'Звонки',
        class: null,
        style: 'background: rgba(0, 73, 153, 0.5)',
      },
      {
        title: 'Продан',
        class: null,
        style: 'background: rgba(40, 167, 69, 0.5)',
      },
      {
        title: 'Снят с продажи',
        class: null,
        style: 'background: rgba(220, 53, 69, 0.5)',
      },
    ],
    relations: [
      'selling',
      'selling.editor',
      'branch',
      'owner',
      'specification.gearbox',
      'specification.fuel',
      'creator',
      'sold.client',
      'deposits.client',
      'sell_reject.creator',
    ],
    relations_count: [
      'deposits',
    ],

    branches: [],
    branch: null,
    employees: [],
    responsible: null,
    marks: [],
    mark: null,
    models: [],
    model: [],

    filter_cars: {
      branch_id: null,
      responsible_id: null,
      mark_id: null,
      model_id: null,
      show_only: null,
      only_sold: false,
      sell_reject: false,
      sorting_date_car: null,
      sorting_date_comment: null,
    },

    onProgressCarModels: false,
  }),

  async created() {
    try {
      this.EventBus.$on("cars-selling-items-reload", this.updateFilters);

      if ([1, 4, 5, 6, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
        this.filter_cars.show_only = "all";
      } else {
        this.filter_cars.show_only = "my";
      }

      await this.updateFilters();

      const [employees_response, car_marks] = await axios.all([
        API.apiClient.get("/employees", {
          params: this.$apiRequestParams.filters_list,
        }),
        API.apiClient.get("/cars/marks?available=1", {
          params: {
            is_paginated: 0,
            sorting: "asc",
            sort_column: "name",
            filters_query: {
              only_available: true,
            },
          },
        }),
      ]);

      this.branches = await BranchService.getItems();
      this.employees = employees_response.data;
      this.marks = car_marks.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.filter_cars.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models/available",
          {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    updateFilters() {
      localStorage.setItem("filter_cars_selling", JSON.stringify(this.filter_cars));

      this.EventBus.$emit('table-filter-changed');
    },
  },
};
</script>
